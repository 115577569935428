import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='49.08' y='188.69' width='22.5' height='35.62' />
			<rect x='42.93' y='192.26' width='6.15' height='12' />
			<line x1='43.9' y1='204.26' x2='43.9' y2='192.26' />
			<rect x='42.93' y='208.76' width='6.15' height='12' />
			<line x1='43.9' y1='208.76' x2='43.9' y2='220.76' />
			<rect x='71.58' y='192.26' width='6.15' height='12' />
			<line x1='76.75' y1='204.26' x2='76.75' y2='192.26' />
			<rect x='71.58' y='208.76' width='6.15' height='12' />
			<line x1='76.75' y1='208.76' x2='76.75' y2='220.76' />
			<rect x='42.11' y='102.75' width='22.5' height='22.5' />
			<rect x='77.84' y='81.37' width='24' height='65.27' />
			<polyline points='80.09,81.37 80.09,84.37 98.84,84.37 98.84,143.64 80.09,143.64 80.09,146.64' />
			<line x1='77.84' y1='123.66' x2='98.84' y2='123.6' />
			<line x1='77.84' y1='104.34' x2='98.84' y2='104.34' />
			<rect x='93.73' y='85.04' width='4.5' height='57.93' />
			<rect x='3.28' y='107.92' width='9' height='45' />
			<rect x='7.37' y='112.42' width='1.12' height='36' />
			<polyline points='7.37,133.42 5.68,133.42 5.68,127.42 7.37,127.42' />
			<polyline points='8.49,127.42 10.18,127.42 10.18,133.42 8.49,133.42' />
			<rect x='214.68' y='86.06' width='0.75' height='45.75' />
			<polyline points='200.83,86.43 155.43,86.43 155.43,131.43 183.5,131.43' />
			<polyline points='197.51,131.43 214.68,131.43 214.68,86.43 206.85,86.43' />
			<rect x='202.15' y='71.85' width='13.29' height='13.29' />
			<rect x='202.15' y='132.72' width='13.29' height='13.29' />
			<polyline points='206.15,89.81 213.26,89.81 213.26,107.43 202.5,107.43' />
			<polyline points='201.41,112.67 201.41,128.06 213.26,128.06 213.26,110.81 201.79,110.81' />
			<polygon points='206.93,86.06 200.98,86.06 183.35,131.81 197.44,131.81' />
			<rect x='126.24' y='202.79' width='18' height='45' />
			<rect x='117.81' y='218.54' width='8.44' height='1.5' />
			<rect x='117.06' y='220.04' width='1.5' height='10.5' />
			<rect x='117.81' y='230.54' width='8.44' height='1.5' />
			<rect x='130.33' y='218.54' width='4.5' height='13.5' />
			<rect x='131.08' y='219.29' width='3' height='1.73' />
			<rect x='131.08' y='221.66' width='3' height='9.64' />
			<polyline points='140.46,223.04 142.14,223.04 142.14,227.17 140.46,227.17' />
			<polyline points='139.33,227.17 137.64,227.17 137.64,223.04 139.33,223.04' />
			<rect x='139.33' y='218.54' width='1.12' height='13.5' />
		</g>
	);
};

export default Furniture;
