import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	hotspot: {
		x: 172,
		y: 212,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.33602201874349547,
		longitude: 3.142594425914338,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8463930073101977,
				longitude: 5.983254495174189,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.08612516530449943,
					longitude: 6.207148572258485,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.364135926746306,
				longitude: 6.029815318550139,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.07237922294148724,
					longitude: 5.651669512497797,
				},
			},
		},
	],
};

export default MainBath;
