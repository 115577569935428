import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<g id='LWPOLYLINE_974_'>
				<polygon
					points='70.88,304.19 69.41,304.19 69.41,300.06 70.88,300.06 73.26,300.06 73.26,247.79 144.21,247.79 
          144.21,199.4 120.55,199.4 120.55,198.96 147.21,198.96 147.21,196.36 120.55,196.36 120.55,195.91 150.74,195.91 150.74,199.4 
          147.77,199.4 147.77,244.37 219.77,244.37 219.77,199.4 214.05,199.4 214.05,197.18 202.24,197.18 202.24,199.4 176.28,199.4 
          176.28,195.91 179.2,195.91 179.2,192.09 182.69,192.09 182.69,194.14 219.77,194.14 219.77,166.3 182.69,166.3 182.69,169.59 
          179.2,169.59 179.2,166.3 163.97,166.3 163.97,162.81 215.43,162.81 215.43,61.16 203.58,61.99 203.41,59.56 204.4,59.47 
          222.74,58.19 223.02,58.21 223.02,89.44 223.02,89.44 223.02,251.03 76.49,251.03 76.49,304.19 		'
				/>
			</g>
			<g id='LWPOLYLINE_975_'>
				<polygon
					points='0.25,105.97 0.25,73.75 10.42,73.04 23.97,72.09 24.14,74.54 12.36,75.36 12.34,107.89 3.49,107.89 
          3.49,175.66 8.68,175.7 8.68,289.37 37.24,289.37 37.24,300.06 42.39,300.06 42.39,304.19 33.1,304.19 33.1,293.12 0.25,293.12 
          0.25,105.97 		'
				/>
			</g>
			<g id='LWPOLYLINE_976_'>
				<polygon
					points='91.06,224.85 91.51,224.85 91.51,199.4 94.11,199.4 94.11,224.85 94.55,224.85 94.55,199.4 
          97.6,199.4 97.6,195.91 91.06,195.91 		'
				/>
			</g>
			<g id='LWPOLYLINE_977_'>
				<polygon
					points='136.95,166.3 138.43,166.3 138.43,162.81 135.74,162.81 135.74,140.17 126.88,140.17 126.88,130.48 
          123.4,130.48 123.4,140.17 114.33,140.17 105.33,140.17 105.33,68.86 123.4,67.6 123.4,76.44 126.88,76.44 126.89,67.35 
          128.47,67.24 128.3,64.8 99.08,66.84 99.24,69.27 101.84,69.1 101.84,166.3 109.64,166.3 109.64,162.81 105.33,162.81 
          105.33,145.42 132.25,145.42 132.25,162.81 127.65,162.81 127.65,166.3 		'
				/>
			</g>
		</g>
	);
};
export default Walls;
