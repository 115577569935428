import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M163.97,162.82c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M152.08,224.91c13.57-0.71,24.21-11.92,24.21-25.51' />
			<path d='M179.2,169.59c-2.8,0-5.57,0.52-8.18,1.54' />
			<path d='M109.64,166.3c0,1.13,0.11,2.26,0.32,3.37' />

			<rect
				x='44.91'
				y='45.13'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.6512 3.2901)'
				width='0.75'
				height='17.59'
			/>
			<path d='M72.34,65.35c-1-14.35-13.24-25.3-27.62-24.7' />
			<path d='M68.08,273.04c-14.39,0.74-25.68,12.61-25.69,27.02' />
		</g>
	);
};

export default DoorSwings;
