import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Player from './Player';
import SCSS from './App.module.scss';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#8D725F',
		},
		secondary: {
			main: '#918881',
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<div className={SCSS.App}>
				<Player />
			</div>
		</ThemeProvider>
	);
}

export default App;
