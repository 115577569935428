import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='176.28' y1='197.66' x2='150.74' y2='197.66' />
			<line x1='180.95' y1='192.09' x2='180.95' y2='169.59' />
			<line x1='128.47' y1='67.24' x2='203.58' y2='61.99' />
			<polyline points='72.57,68.71 72.74,71.14 99.25,69.27 		' />
			<polyline points='46.06,70.57 46.23,72.99 24.14,74.52 		' />
		</g>
	);
};

export default index;
