import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const LivingRoom: IScene = {
	name: 'Living Room',
	hotspot: {
		x: 40,
		y: 145,
	},
	images: {
		scheme1: Scheme1.LivingRoom,
		scheme2: Scheme2.LivingRoom,
	},
	default: {
		latitude: -0.11272576266459566,
		longitude: 0.27589442309256734,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4216867512892817,
				longitude: 3.3188877605924807,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.04168955211862935,
					longitude: 3.2087254139441588,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4228426183772709,
				longitude: 2.438401983491968,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.03068920065873071,
					longitude: 2.551547695724495,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5090878244934087,
				longitude: 2.2477521922164607,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.034539944167273795,
					longitude: 2.3998228386941416,
				},
			},
		},
	],
};

export default LivingRoom;
