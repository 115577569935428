import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Office: IScene = {
	name: 'Office',
	hotspot: {
		x: 105,
		y: 215,
	},
	images: {
		scheme1: Scheme1.Office,
		scheme2: Scheme2.Office,
	},
	default: {
		latitude: -0.21796091860878297,
		longitude: 1.5866260714362135,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8591139526544449,
				longitude: 6.263286707814795,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: 0.002145680237110703,
					longitude: 5.998371722131626,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.531250645835331,
				longitude: 5.9170346800426366,
			},
			target: {
				name: 'Living Room',
				view: {
					latitude: 0.014315909624166512,
					longitude: 6.194513845966123,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.45647382439432427,
				longitude: 4.277094593877399,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: 0.009333182930521655,
					longitude: 4.713274270636064,
				},
			},
		},
	],
};

export default Office;
