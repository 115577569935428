import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='17.68' y1='175.7' x2='17.68' y2='177.2' />
			<line x1='17.68' y1='179.23' x2='17.68' y2='234.06' />
			<line x1='17.68' y1='235.07' x2='17.68' y2='236.57' />
			<line x1='26.68' y1='194.45' x2='25.18' y2='194.45' />
			<line x1='22.81' y1='194.45' x2='12.12' y2='194.45' />
			<polyline points='10.93,194.45 9.43,194.45 9.43,192.95' />
			<line x1='9.43' y1='191.19' x2='9.43' y2='178.83' />
			<polyline points='9.43,177.95 9.43,176.45 10.93,176.45' />
			<line x1='13.31' y1='176.45' x2='23.99' y2='176.45' />
			<line x1='25.18' y1='176.45' x2='26.68' y2='176.45' />
			<line x1='8.68' y1='255.32' x2='10.18' y2='255.32' />
			<line x1='11.93' y1='255.32' x2='19.81' y2='255.32' />
			<polyline points='20.68,255.32 22.18,255.32 22.18,253.82' />
			<line x1='22.18' y1='251.97' x2='22.18' y2='239' />
			<polyline points='22.18,238.07 22.18,236.57 20.68,236.57' />
			<line x1='18.93' y1='236.57' x2='11.06' y2='236.57' />
			<line x1='10.18' y1='236.57' x2='8.68' y2='236.57' />
			<line x1='17.68' y1='255.32' x2='17.68' y2='256.82' />
			<line x1='17.68' y1='259' x2='17.68' y2='268.78' />
			<line x1='17.68' y1='269.87' x2='17.68' y2='271.37' />
			<line x1='112.83' y1='140.17' x2='112.83' y2='138.67' />
			<line x1='112.83' y1='136.63' x2='112.83' y2='71.43' />
			<line x1='112.83' y1='70.41' x2='112.83' y2='68.91' />
			<line x1='132.25' y1='152.92' x2='130.75' y2='152.92' />
			<line x1='128.58' y1='152.92' x2='107.91' y2='152.92' />
			<line x1='106.83' y1='152.92' x2='105.33' y2='152.92' />
		</g>
	);
};

export default Dashed;
