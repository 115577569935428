import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='207.77' cy='210.55' r='0.75' />
			<line x1='152.2' y1='229.23' x2='152.2' y2='240.03' />
			<path
				d='M160.31,238.3c0.34-0.31,0.36-0.84,0.04-1.18s-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18
			c0.01,0.01,0.03,0.03,0.04,0.04'
			/>
			<path d='M159.17,241.07c-0.1,0-0.18,0.09-0.18,0.19' />
			<line x1='158.99' y1='242.38' x2='158.99' y2='241.26' />
			<path d='M158.99,242.38c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='159.6' y1='242.57' x2='159.18' y2='242.57' />
			<line x1='160.3' y1='242.57' x2='159.88' y2='242.57' />
			<path d='M160.3,242.57c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='160.49' y1='241.26' x2='160.49' y2='242.38' />
			<path d='M160.49,241.26c0-0.1-0.08-0.18-0.18-0.19' />
			<line x1='159.88' y1='242.95' x2='159.6' y2='242.95' />
			<line x1='159.88' y1='242.39' x2='159.88' y2='242.95' />
			<line x1='159.6' y1='242.95' x2='159.6' y2='242.39' />
			<line x1='167.27' y1='240.03' x2='160.31' y2='240.03' />
			<line x1='167.27' y1='229.23' x2='167.27' y2='240.03' />
			<line x1='152.2' y1='229.23' x2='167.27' y2='229.23' />
			<line x1='159.17' y1='240.03' x2='152.2' y2='240.03' />
			<path
				d='M177.97,235.96c0.03,0.41,0.07,0.57,0.11,0.71c0.04,0.13,0.08,0.24,0.14,0.35c0.06,0.11,0.12,0.23,0.19,0.32
			c0.07,0.1,0.13,0.18,0.2,0.25c0.08,0.08,0.16,0.15,0.26,0.22c0.12,0.08,0.26,0.17,0.4,0.24c0.16,0.08,0.32,0.14,0.47,0.19
			c0.16,0.05,0.31,0.09,0.47,0.12c0.18,0.04,0.38,0.07,0.57,0.09c0.24,0.03,0.47,0.05,0.71,0.06c0.31,0.02,0.62,0.03,0.97,0.03
			c0.43,0.01,0.91,0.01,1.41-0.01c0.47-0.01,0.95-0.04,1.34-0.08c0.32-0.03,0.56-0.07,0.85-0.14c0.19-0.05,0.4-0.11,0.57-0.18
			c0.15-0.05,0.27-0.11,0.39-0.17c0.11-0.06,0.21-0.13,0.33-0.23c0.12-0.1,0.25-0.23,0.35-0.36c0.1-0.12,0.18-0.24,0.24-0.35
			c0.06-0.09,0.11-0.18,0.15-0.28c0.04-0.09,0.07-0.19,0.09-0.3c0.02-0.1,0.04-0.19,0.06-0.31c0.02-0.12,0.04-0.27,0.04-0.65'
			/>
			<path
				d='M177.97,229.86c0-0.3,0.01-0.53,0.04-0.74c0.01-0.1,0.02-0.2,0.04-0.3c0.01-0.09,0.03-0.19,0.05-0.28
			c0.02-0.1,0.03-0.19,0.05-0.29c0.02-0.1,0.04-0.2,0.05-0.29s0.04-0.2,0.06-0.29c0.02-0.1,0.04-0.19,0.07-0.29
			c0.02-0.09,0.05-0.19,0.08-0.28s0.06-0.18,0.09-0.27c0.03-0.09,0.07-0.17,0.11-0.25c0.04-0.08,0.08-0.16,0.12-0.24
			c0.09-0.16,0.18-0.31,0.28-0.45c0.1-0.14,0.21-0.28,0.31-0.4c0.11-0.13,0.21-0.24,0.31-0.34c0.1-0.1,0.2-0.19,0.29-0.26
			c0.05-0.04,0.09-0.07,0.13-0.1s0.09-0.06,0.13-0.08c0.05-0.03,0.09-0.05,0.15-0.08c0.05-0.03,0.11-0.05,0.18-0.08
			c0.07-0.03,0.14-0.06,0.21-0.08c0.07-0.03,0.15-0.05,0.23-0.08s0.16-0.05,0.24-0.07c0.08-0.02,0.16-0.04,0.24-0.06
			c0.16-0.04,0.32-0.06,0.47-0.08c0.08-0.01,0.15-0.02,0.22-0.02s0.14-0.01,0.22-0.01c0.14-0.01,0.29-0.01,0.46-0.01
			c0.17,0,0.35,0,0.54,0c0.19,0,0.38,0.01,0.55,0.02c0.09,0.01,0.17,0.01,0.25,0.02s0.16,0.02,0.24,0.03
			c0.08,0.01,0.15,0.02,0.23,0.04c0.04,0.01,0.07,0.01,0.11,0.02c0.04,0.01,0.07,0.01,0.11,0.02c0.07,0.01,0.14,0.03,0.2,0.04
			c0.06,0.01,0.12,0.03,0.18,0.04c0.03,0.01,0.05,0.02,0.08,0.02c0.03,0.01,0.05,0.02,0.08,0.03c0.05,0.02,0.11,0.04,0.16,0.07
			c0.05,0.03,0.11,0.06,0.17,0.09s0.12,0.07,0.17,0.11c0.06,0.04,0.12,0.07,0.18,0.11c0.06,0.04,0.12,0.08,0.17,0.12
			c0.06,0.04,0.11,0.09,0.17,0.14c0.06,0.05,0.12,0.1,0.18,0.16s0.12,0.12,0.18,0.19c0.06,0.07,0.12,0.14,0.19,0.22
			c0.12,0.15,0.25,0.32,0.36,0.49c0.06,0.08,0.11,0.17,0.16,0.25c0.05,0.08,0.09,0.16,0.13,0.23c0.04,0.08,0.07,0.15,0.1,0.22
			c0.03,0.07,0.06,0.14,0.08,0.21c0.02,0.07,0.05,0.14,0.07,0.2c0.02,0.07,0.04,0.14,0.06,0.2c0.02,0.07,0.04,0.14,0.06,0.21
			c0.02,0.07,0.04,0.14,0.05,0.21c0.02,0.07,0.04,0.15,0.05,0.23c0.02,0.08,0.04,0.17,0.05,0.27c0.01,0.05,0.02,0.1,0.03,0.16
			s0.02,0.11,0.03,0.17c0.02,0.12,0.04,0.25,0.05,0.39s0.03,0.28,0.04,0.42c0.01,0.14,0.02,0.28,0.02,0.41'
			/>
			<line x1='177.97' y1='229.86' x2='177.97' y2='235.96' />
			<line x1='188.28' y1='229.86' x2='188.28' y2='235.49' />
			<line x1='196.6' y1='170.62' x2='196.6' y2='191.06' />
			<line x1='46.06' y1='70.57' x2='72.57' y2='68.71' />
			<line x1='45.83' y1='67.2' x2='72.34' y2='65.35' />
			<rect x='42.39' y='300.06' width='27.02' height='4.13' />
			<line x1='24.81' y1='288.62' x2='24.81' y2='272.12' />
			<path d='M27.06,191.68c1.2-4.07,1.2-8.39,0-12.46' />
			<circle cx='11.31' cy='203.92' r='0.19' />
			<line x1='10.65' y1='204.49' x2='11.98' y2='204.49' />
			<polyline points='13.18,206.12 13.18,214.7 24.43,214.7 24.43,196.7 13.18,196.7 13.18,205.28 		' />
			<path
				d='M16.99,205.99c0.16,0.38,0.6,0.56,0.98,0.41c0.38-0.16,0.56-0.6,0.41-0.98s-0.6-0.56-0.98-0.41
			c-0.18,0.08-0.33,0.22-0.41,0.41'
			/>
			<line x1='10.52' y1='245.95' x2='22.59' y2='245.95' />
			<line x1='22.18' y1='237.78' x2='22.18' y2='241.25' />
			<line x1='22.18' y1='241.49' x2='22.18' y2='245.34' />
			<rect x='10.52' y='237.18' width='12.07' height='17.53' />
			<path d='M19.82,240.7c-0.28,0.05-0.5,0.26-0.55,0.54' />
			<path d='M20.59,241.25c-0.05-0.28-0.27-0.49-0.55-0.54' />
			<path d='M20.04,242.03c0.28-0.05,0.5-0.26,0.55-0.54' />
			<path d='M19.27,241.49c0.05,0.28,0.27,0.49,0.55,0.54' />
			<path d='M19.82,240.07c-0.63,0.05-1.13,0.55-1.19,1.18' />
			<path d='M21.23,241.25c-0.06-0.63-0.56-1.13-1.19-1.18' />
			<path d='M20.04,242.67c0.63-0.05,1.13-0.55,1.19-1.18' />
			<path d='M18.63,241.49c0.06,0.63,0.56,1.13,1.19,1.18' />
			<path d='M20.94,250.37c-0.06-0.47-0.43-0.84-0.9-0.89' />
			<path d='M20.04,251.51c0.47-0.05,0.85-0.42,0.9-0.89' />
			<path d='M18.91,250.62c0.06,0.47,0.43,0.84,0.9,0.89' />
			<path d='M19.82,249.48c-0.47,0.05-0.85,0.42-0.9,0.89' />
			<path d='M21.59,250.37c-0.06-0.83-0.72-1.48-1.55-1.54' />
			<path d='M20.04,252.15c0.83-0.05,1.49-0.71,1.55-1.54' />
			<path d='M18.27,250.62c0.06,0.83,0.72,1.48,1.55,1.54' />
			<path d='M19.82,248.83c-0.83,0.05-1.49,0.71-1.55,1.54' />
			<path d='M15.25,250.37c-0.06-0.68-0.6-1.22-1.29-1.28' />
			<path d='M13.96,251.89c0.68-0.05,1.23-0.59,1.29-1.28' />
			<path d='M12.45,250.62c0.06,0.68,0.6,1.22,1.29,1.28' />
			<path d='M13.74,249.09c-0.68,0.05-1.23,0.59-1.29,1.28' />
			<path d='M15.7,250.37c-0.06-0.93-0.81-1.67-1.74-1.73' />
			<path d='M13.96,252.35c0.93-0.06,1.68-0.8,1.74-1.73' />
			<path d='M12,250.62c0.06,0.93,0.81,1.67,1.74,1.73' />
			<path d='M13.74,248.64c-0.93,0.06-1.68,0.8-1.74,1.73' />
			<path d='M13.2,241.49c0.05,0.27,0.27,0.49,0.54,0.53' />
			<path d='M13.74,240.71c-0.27,0.05-0.49,0.26-0.54,0.53' />
			<path d='M14.5,241.25c-0.05-0.27-0.27-0.49-0.54-0.53' />
			<path d='M13.96,242.02c0.27-0.05,0.49-0.26,0.54-0.53' />
			<path d='M11.84,241.49c0.06,1.02,0.88,1.83,1.9,1.89' />
			<path d='M13.74,239.36c-1.02,0.06-1.84,0.87-1.9,1.89' />
			<path d='M15.86,241.25c-0.06-1.02-0.88-1.83-1.9-1.89' />
			<path d='M13.96,243.38c1.02-0.06,1.84-0.87,1.9-1.89' />
			<path
				d='M24.64,242.42c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6s0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='24.88' y1='242.42' x2='23.83' y2='242.42' />
			<line x1='24.88' y1='242.83' x2='23.83' y2='242.83' />
			<path
				d='M24.64,244.62c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='24.88' y1='244.62' x2='23.83' y2='244.62' />
			<line x1='24.88' y1='245.02' x2='23.83' y2='245.02' />
			<path
				d='M24.64,249.06c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6s0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='24.88' y1='249.47' x2='23.83' y2='249.47' />
			<line x1='24.88' y1='249.06' x2='23.83' y2='249.06' />
			<path
				d='M24.64,246.87c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6s0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='24.88' y1='247.27' x2='23.83' y2='247.27' />
			<line x1='24.88' y1='246.87' x2='23.83' y2='246.87' />
			<rect x='10.82' y='246.55' width='2.93' height='3.82' />
			<rect x='10.82' y='250.62' width='2.93' height='3.49' />
			<rect x='13.96' y='250.62' width='2.83' height='3.49' />
			<rect x='13.96' y='246.55' width='2.83' height='3.82' />
			<rect x='17.01' y='246.55' width='2.81' height='3.82' />
			<rect x='17.01' y='250.62' width='2.81' height='3.49' />
			<rect x='20.04' y='250.62' width='2.14' height='3.49' />
			<rect x='20.04' y='246.55' width='2.14' height='3.82' />
			<rect x='20.04' y='241.49' width='2.14' height='3.85' />
			<rect x='20.04' y='237.78' width='2.14' height='3.46' />
			<rect x='17.01' y='237.78' width='2.81' height='3.46' />
			<rect x='17.01' y='241.49' width='2.81' height='3.85' />
			<rect x='13.96' y='241.49' width='2.83' height='3.85' />
			<rect x='13.96' y='237.78' width='2.83' height='3.46' />
			<rect x='10.82' y='237.78' width='2.93' height='3.46' />
			<rect x='10.82' y='241.49' width='2.93' height='3.85' />
			<path d='M27.06,252.18c1.2-4.07,1.2-8.39,0-12.46' />
		</g>
	);
};

export default ThinLineworks;
