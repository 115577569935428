import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	hotspot: {
		x: 140,
		y: 110,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.10393218104125079,
		longitude: 1.5843720177418805,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5274887720104102,
				longitude: 3.1470604856418976,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.020816130229400542,
					longitude: 3.146870159494563,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3620352459784115,
				longitude: 3.045620516645775,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.1878420819665232,
					longitude: 3.1331440054603927,
				},
			},
		},
	],
};

export default Bedroom;
