import IScene from './IScene';
import Bedroom from './Bedroom';
import Hallway from './Hallway';
import Kitchen from './Kitchen';
import LivingRoom from './LivingRoom';
import MainBath from './MainBath';
import Office from './Office';

const Scenes: Array<IScene> = [
	Kitchen,
	Hallway,
	LivingRoom,
	Bedroom,
	MainBath,
	Office,
];

export default Scenes;
