import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import panoramaSlice from '../features/panorama/panoramaSlice';
export const store = configureStore({
	reducer: {
		panorama: panoramaSlice,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
