import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Hallway: IScene = {
	name: 'Hallway',
	hotspot: {
		x: 160,
		y: 180,
	},
	images: {
		scheme1: Scheme1.Hallway,
		scheme2: Scheme2.Hallway,
	},
	default: {
		latitude: -0.1264003061914294,
		longitude: 4.796720232689106,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8054435383314709,
				longitude: 2.2859155655402903,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.06051407843051848,
					longitude: 2.2096172429499594,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.06911883455934942,
				longitude: 4.381738756277461,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: 0.0027720304150324893,
					longitude: 4.712458050243682,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.49544254917480357,
				longitude: 4.942417995942646,
			},
			target: {
				name: 'Living Room',
				view: {
					latitude: -0.19234836110152642,
					longitude: 5.38779277274431,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8628020827550831,
				longitude: 0.7403309216644803,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.021930731562035177,
					longitude: 0.987546545341634,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7780245459972157,
				longitude: 3.8930135803192245,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.12824440472794763,
					longitude: 3.9839259114101293,
				},
			},
		},
	],
};

export default Hallway;
