import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polygon
				id='Interior'
				className={SCSS.Interiors}
				points='0.25,70.39 0.25,293.12 33.1,293.12 33.1,304.19 76.49,304.19 76.49,251.03 
		223.02,251.03 223.02,54.81 	'
			/>
			<polygon
				id='Balcony'
				className={SCSS.Balcony}
				points='22.4,40.67 223.02,0.31 223.02,54.81 24.6,68.66 24.59,67.16 23.32,67.24 	'
			/>
		</g>
	);
};

export default Backdrop;
