import {useState} from '@wordpress/element';
import {useDispatch, useSelector} from 'react-redux';
import {Viewer} from 'photo-sphere-viewer';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import SCSS from './ActionPanel.module.scss';
import {
	openPOP,
	selectPop,
	selectMiniPlan,
	selectScheme,
	changeScheme,
	updateClicked,
	toggleMiniPlan,
} from '../../../features/panorama/panoramaSlice';
const ScreenSizeDetector = require('screen-size-detector');

const useStyles = makeStyles({
	root: {
		// width: 500,
		backdropFilter: 'blur(5px)',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		// borderRadius: '5px',
	},
	buttonWrapper: {
		color: 'rgba(255, 255, 255, 0.8) !important',
	},
	buttonSelected: {
		color: 'rgba(255, 255, 255, 1) !important',
	},
	tooltip: {
		fontSize: '18px',
		letterSpacing: '0.02rem',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		backdropFilter: 'blur(5px)',
		color: 'rgba(255, 255, 255, 0.8)',
	},
	arrow: {
		color: 'rgba(0, 0, 0, 0.5)',
	},
	// scheme1: {
	// 	backgroundColor: '#222',
	// },
	// scheme2: {
	// 	backgroundColor: '#222',
	// },
});

type typeViewer = typeof Viewer;

type Props = {
	view: typeViewer;
	enterFullscreen: () => void;
	exitFullscreen: () => void;
	fullscreenStatus: boolean;
};

const ActionPanel = (props: Props) => {
	const {view, enterFullscreen, exitFullscreen, fullscreenStatus} = props;
	const [rotate, setRotate] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const pop = useSelector(selectPop);
	const scheme = useSelector(selectScheme);
	const miniplan = useSelector(selectMiniPlan);
	const screen = new ScreenSizeDetector();
	/**********************************
	 * Functions
	 *********************************/
	const handleChange = (event: any, value: string) => {
		switch (value) {
			case 'rotate':
				view.toggleAutorotate();
				break;
			case 'enter fullscreen':
				enterFullscreen();
				break;
			case 'exit fullscreen':
				exitFullscreen();
				break;
			case 'scheme1':
				dispatch(updateClicked('scheme'));
				dispatch(changeScheme('scheme1'));
				break;
			case 'scheme2':
				dispatch(updateClicked('scheme'));
				dispatch(changeScheme('scheme2'));
				break;
			case 'selection':
				dispatch(openPOP());
				break;
			case 'floorplan':
				dispatch(toggleMiniPlan(true));
				break;
		}
	};

	if (view) {
		const cv = view as typeViewer;
		cv.on('autorotate', (event: any, data: any) => {
			setRotate(data);
		});
	}

	return !pop ? (
		<div className={SCSS.ActionPanel}>
			<div className={SCSS.ButtonContainer}>
				<BottomNavigation
					onChange={handleChange}
					showLabels={false}
					classes={{root: classes.root}}>
					{fullscreenStatus ? (
						<Tooltip
							title='Exit Fullscreen'
							placement='top'
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
							arrow>
							<BottomNavigationAction
								label=''
								icon={<FullscreenExitIcon />}
								value='exit fullscreen'
								showLabel={false}
								classes={{
									wrapper: classes.buttonWrapper,
									selected: classes.buttonSelected,
								}}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title='Fullscreen'
							placement='top'
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
							arrow>
							<BottomNavigationAction
								label=''
								icon={<FullscreenOutlinedIcon />}
								value='enter fullscreen'
								showLabel={false}
								classes={{
									wrapper: classes.buttonWrapper,
									selected: classes.buttonSelected,
								}}
							/>
						</Tooltip>
					)}

					{rotate ? (
						<Tooltip
							title='Stop Rotate'
							placement='top'
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
							arrow>
							<BottomNavigationAction
								label=''
								icon={<StopIcon />}
								value='rotate'
								showLabel={false}
								classes={{
									wrapper: classes.buttonWrapper,
									selected: classes.buttonSelected,
								}}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title='Start Rotate'
							placement='top'
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
							arrow>
							<BottomNavigationAction
								label=''
								icon={<PlayArrowIcon />}
								value='rotate'
								showLabel={false}
								classes={{
									wrapper: classes.buttonWrapper,
									selected: classes.buttonSelected,
								}}
							/>
						</Tooltip>
					)}

					<Tooltip
						title='Scheme 1'
						placement='top'
						classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						arrow>
						<BottomNavigationAction
							label=''
							icon={
								<Badge badgeContent={1} color='primary'>
									<ColorLensIcon />
								</Badge>
							}
							value='scheme1'
							showLabel={false}
							classes={{
								wrapper: classes.buttonWrapper,
								selected: classes.buttonSelected,
							}}
						/>
					</Tooltip>

					<Tooltip
						title='Scheme 2'
						placement='top'
						classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						arrow>
						<BottomNavigationAction
							label=''
							icon={
								<Badge badgeContent={2} color='secondary'>
									<ColorLensIcon />
								</Badge>
							}
							value='scheme2'
							showLabel={false}
							classes={{
								wrapper: classes.buttonWrapper,
								selected: classes.buttonSelected,
							}}
						/>
					</Tooltip>

					<Tooltip
						title='Selection'
						placement='top'
						classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						arrow>
						<BottomNavigationAction
							label=''
							icon={<HomeWorkOutlinedIcon />}
							value='selection'
							showLabel={false}
							classes={{
								wrapper: classes.buttonWrapper,
								selected: classes.buttonSelected,
							}}
						/>
					</Tooltip>

					{screen.width > 850 && (
						<Tooltip
							title={miniplan ? 'Hide Floorplan' : 'Show Floorplan'}
							placement='top'
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
							arrow>
							<BottomNavigationAction
								label=''
								icon={<EmojiFlagsIcon />}
								value='floorplan'
								showLabel={false}
								classes={{
									wrapper: classes.buttonWrapper,
									selected: classes.buttonSelected,
								}}
							/>
						</Tooltip>
					)}
				</BottomNavigation>
			</div>
		</div>
	) : null;
};

export default ActionPanel;
