import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	hotspot: {
		x: 40,
		y: 242,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.1184827420019956,
		longitude: 6.234339858919149,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4104130144570428,
				longitude: 0.12573641854272838,
			},
			target: {
				name: 'Living Room',
				view: {
					latitude: -0.11860232573329066,
					longitude: 6.224599082036825,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.46784502244212245,
				longitude: 0.6864040728972632,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.03469708909367508,
					longitude: 1.0386022953208625,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6701965133981593,
				longitude: 1.4586918648721732,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.0813052122108946,
					longitude: 1.5918868826652781,
				},
			},
		},
	],
};

export default Kitchen;
