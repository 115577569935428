import IImage from './IImage';
import Scheme_1_Bedroom from './The_Grand_Unit_J-Scheme_01-Bedroom.jpg';
import Scheme_1_Hallway from './The_Grand_Unit_J-Scheme_01-Hallway.jpg';
import Scheme_1_Kitchen from './The_Grand_Unit_J-Scheme_01-Kitchen.jpg';
import Scheme_1_LivingRoom from './The_Grand_Unit_J-Scheme_01-Living-Room.jpg';
import Scheme_1_MainBath from './The_Grand_Unit_J-Scheme_01-Main-Bath.jpg';
import Scheme_1_Office from './The_Grand_Unit_J-Scheme_01-Office.jpg';

import Scheme_2_Bedroom from './The_Grand_Unit_J-Scheme_02-Bedroom.jpg';
import Scheme_2_Hallway from './The_Grand_Unit_J-Scheme_02-Hallway.jpg';
import Scheme_2_Kitchen from './The_Grand_Unit_J-Scheme_02-Kitchen.jpg';
import Scheme_2_LivingRoom from './The_Grand_Unit_J-Scheme_02-Living-Room.jpg';
import Scheme_2_MainBath from './The_Grand_Unit_J-Scheme_02-Main-Bath.jpg';
import Scheme_2_Office from './The_Grand_Unit_J-Scheme_02-Office.jpg';

export const Scheme1: IImage = {
	Bedroom: Scheme_1_Bedroom,
	Hallway: Scheme_1_Hallway,
	Kitchen: Scheme_1_Kitchen,
	LivingRoom: Scheme_1_LivingRoom,
	MainBath: Scheme_1_MainBath,
	Office: Scheme_1_Office,
};

export const Scheme2: IImage = {
	Bedroom: Scheme_2_Bedroom,
	Hallway: Scheme_2_Hallway,
	Kitchen: Scheme_2_Kitchen,
	LivingRoom: Scheme_2_LivingRoom,
	MainBath: Scheme_2_MainBath,
	Office: Scheme_2_Office,
};
