import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<polygon points='222.13,54.87 223.02,54.81 223.02,58.21 222.74,58.19 222.37,58.22 222.13,54.87 		' />

			<rect
				x='204.26'
				y='55.5'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.4687 15.0159)'
				width='18.01'
				height='3.36'
			/>
			<polygon points='204.4,59.47 202.9,59.6 202.67,56.23 204.16,56.13 		' />

			<rect
				x='180.45'
				y='57.74'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.7039 12.7846)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='155.65'
				y='58.66'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.8 11.8706)'
				width='24.83'
				height='3.38'
			/>

			<rect
				x='154.18'
				y='59.57'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.896 10.9566)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='129.14'
				y='60.5'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.993 10.0342)'
				width='25.07'
				height='3.38'
			/>
			<polygon points='127.79,64.83 129.29,64.75 129.05,61.38 127.55,61.48 		' />

			<rect
				x='99.68'
				y='62.46'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.1969 8.087)'
				width='28.03'
				height='3.36'
			/>

			<rect
				x='98.21'
				y='63.49'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.3047 7.0621)'
				width='1.5'
				height='3.36'
			/>
			<polygon points='98.1,63.54 73.08,65.29 73.32,68.66 98.33,66.89 		' />
			<polygon points='0.25,70.39 1.04,70.33 1.28,73.68 0.25,73.75 		' />

			<rect
				x='1.16'
				y='70.27'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -5.0145 0.3083)'
				width='1.5'
				height='3.36'
			/>

			<rect
				x='2.63'
				y='69.51'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.9343 1.0718)'
				width='20.5'
				height='3.36'
			/>

			<rect
				x='23.1'
				y='68.74'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.854 1.8354)'
				width='1.5'
				height='3.36'
			/>
			<polygon points='24.48,68.69 45.08,67.25 45.31,70.62 24.72,72.04 		' />

			<rect
				x='45.2'
				y='67.22'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.6959 3.3467)'
				width='0.75'
				height='3.38'
			/>

			<rect
				x='72.45'
				y='65.32'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.4965 5.2435)'
				width='0.75'
				height='3.38'
			/>

			<rect
				x='181.92'
				y='56.96'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -3.6222 13.5613)'
				width='20.89'
				height='3.38'
			/>
			<line x1='202.78' y1='57.92' x2='181.95' y2='59.37' />
			<line x1='180.45' y1='59.48' x2='155.68' y2='61.21' />
			<line x1='154.18' y1='61.31' x2='129.17' y2='63.06' />
			<line x1='98.22' y1='65.23' x2='73.2' y2='66.98' />
			<line x1='45.2' y1='68.94' x2='24.6' y2='70.37' />
			<rect x='221.71' y='52.99' width='2.62' height='0.47' />
			<line x1='223.02' y1='54.81' x2='223.02' y2='53.46' />
			<rect x='222.65' y='3.36' width='0.75' height='49.63' />
			<rect x='221.71' y='2.89' width='2.62' height='0.47' />
			<line x1='223.02' y1='2.89' x2='223.02' y2='1.45' />
			<polygon points='223.02,1.45 223.02,0.31 22.4,40.67 23.32,67.24 24.45,67.2 23.55,41.59 223.02,1.45 		' />
			<rect x='123.93' y='76.44' width='1.48' height='27.77' />
			<rect x='125.4' y='90.33' width='1.48' height='27.77' />
			<rect x='118.67' y='196.92' width='25.54' height='1.48' />
			<rect x='92.07' y='201.18' width='1.48' height='25.54' />
			<rect x='195.77' y='199.4' width='24' height='45' />
			<path
				d='M217.4,222.97l-2.07,17.19c-0.14,1.13-1.1,1.98-2.23,1.98h-10.63c-1.14,0-2.1-0.85-2.23-1.98l-2.07-17.19
			c-0.09-0.71-0.09-1.44,0-2.15l2.07-17.19c0.14-1.13,1.1-1.98,2.23-1.98h10.63c1.14,0,2.1,0.85,2.23,1.98l2.07,17.19
			C217.48,221.54,217.48,222.26,217.4,222.97z'
			/>
			<rect x='138.43' y='137.31' width='1.33' height='25.51' />
			<rect x='150.74' y='199.4' width='1.33' height='25.51' />
			<line x1='114.33' y1='68.91' x2='105.33' y2='68.91' />
			<polyline points='195.77,238.58 171.77,238.58 171.77,227.49 147.77,227.49 		' />
			<line x1='151.64' y1='228.6' x2='151.64' y2='240.6' />
			<line x1='160.12' y1='237.84' x2='159.36' y2='237.84' />
			<path d='M159.36,238.95c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='160.12' y1='238.95' x2='159.36' y2='238.95' />
			<path d='M160.31,239.14c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='159.17' y1='238.02' x2='159.17' y2='242.25' />
			<path d='M159.36,237.84c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='160.12' y1='237.84' x2='159.36' y2='237.84' />
			<path d='M160.31,238.02c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='160.31' y1='242.25' x2='160.31' y2='238.02' />
			<path d='M159.17,242.25c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='160.17' y1='242.39' x2='159.31' y2='242.39' />
			<path d='M160.17,242.39c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='167.84' y1='240.6' x2='160.31' y2='240.6' />
			<line x1='167.84' y1='228.6' x2='167.84' y2='240.6' />
			<line x1='151.64' y1='228.6' x2='167.84' y2='228.6' />
			<line x1='159.17' y1='240.6' x2='151.64' y2='240.6' />
			<path
				d='M177.59,229.86c0-0.67,0.07-1.06,0.14-1.48c0.09-0.54,0.2-1.12,0.4-1.65c0.21-0.55,0.53-1.04,0.86-1.43
			c0.25-0.3,0.5-0.55,0.73-0.73c0.19-0.15,0.36-0.24,0.6-0.35c0.23-0.1,0.51-0.2,0.8-0.28c0.31-0.08,0.62-0.14,0.91-0.17
			c0.3-0.03,0.57-0.03,0.91-0.03c0.32,0,0.7,0,1.04,0.03c0.32,0.03,0.61,0.08,0.87,0.12c0.22,0.04,0.43,0.08,0.63,0.15
			c0.21,0.08,0.41,0.19,0.61,0.32c0.29,0.18,0.58,0.38,0.89,0.71c0.33,0.35,0.69,0.84,0.92,1.26c0.22,0.41,0.32,0.76,0.41,1.1
			c0.08,0.29,0.15,0.56,0.23,1.04c0.07,0.39,0.13,0.92,0.13,1.4'
			/>
			<line x1='188.65' y1='229.86' x2='188.65' y2='238.58' />
			<line x1='177.59' y1='229.86' x2='177.59' y2='238.58' />
			<rect x='194.09' y='170.62' width='22.69' height='20.44' />
			<rect
				x='174.45'
				y='170.38'
				transform='matrix(0.9085 -0.4178 0.4178 0.9085 -59.8645 89.7812)'
				width='1.33'
				height='22.46'
			/>

			<rect
				x='109.82'
				y='167.32'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -39.4286 36.4718)'
				width='17.96'
				height='1.33'
			/>
			<rect x='8.68' y='288.62' width='18' height='0.75' />
			<rect x='8.68' y='271.37' width='18' height='0.75' />
			<polyline points='26.68,288.62 26.68,175.7 8.68,175.66 		' />

			<rect
				x='44.14'
				y='40.66'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -2.8846 3.2096)'
				width='0.75'
				height='4.5'
			/>

			<rect
				x='45.68'
				y='62.69'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -4.4174 3.3707)'
				width='0.75'
				height='4.49'
			/>
			<rect x='68.08' y='273.04' width='1.33' height='27.02' />
			<line x1='24.48' y1='68.69' x2='24.38' y2='67.2' />
			<rect x='26.68' y='176.45' width='0.38' height='18' />
			<path d='M27.06,193.44c1.95-5.15,1.95-10.84,0-15.99' />
			<line x1='114.33' y1='140.17' x2='114.33' y2='68.91' />
			<line x1='132.25' y1='154.42' x2='105.33' y2='154.42' />
			<rect x='10.18' y='195.2' width='15.75' height='21' />
			<path d='M11.31,205.28c-0.23,0-0.42,0.19-0.42,0.42c0,0.23,0.19,0.42,0.42,0.42' />
			<path
				d='M11.82,205.28c-0.23-0.28-0.65-0.32-0.93-0.09c-0.28,0.23-0.32,0.65-0.09,0.93c0.23,0.28,0.65,0.32,0.93,0.09
			c0.03-0.03,0.06-0.06,0.09-0.09'
			/>
			<polyline points='11.31,205.28 11.31,205.28 16.68,205.28 		' />
			<line x1='16.68' y1='206.12' x2='11.31' y2='206.12' />
			<polyline points='10.65,203.65 11.98,203.65 11.98,205.28 		' />
			<line x1='10.65' y1='203.65' x2='10.65' y2='205.7' />
			<path d='M16.68,206.12c0.23,0,0.42-0.19,0.42-0.42c0-0.23-0.19-0.42-0.42-0.42' />
			<path
				d='M25.56,254.72v-17.54c0-0.13-0.1-0.23-0.23-0.23h-14.8c-0.13,0-0.23,0.1-0.23,0.23v17.54
			c0,0.13,0.1,0.23,0.23,0.23h14.8C25.45,254.95,25.56,254.84,25.56,254.72z'
			/>
			<circle cx='24.23' cy='242.63' r='0.68' />
			<circle cx='24.23' cy='244.82' r='0.68' />
			<circle cx='24.23' cy='249.27' r='0.68' />
			<circle cx='24.23' cy='247.07' r='0.68' />
			<rect x='26.68' y='236.95' width='0.38' height='18' />
			<path d='M27.06,253.94c1.95-5.15,1.95-10.84,0-15.99' />
		</g>
	);
};

export default Lineworks;
